import styled from 'styled-components';

export const ModalWrapper = styled.div`
  width: 60%;
  height: 550px;
  background-color: #efefef;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 11px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 20% 55% 25%;
  padding: 33px;
  overflow: hidden;

  @media only screen and (min-width: 2000px) {
    width: 40%;
    left: 12%;
    transform: translate(-12%, -50%);
  }

  @media only screen and (max-width: 1439px) {
    width: 80%;
    height: 80%;
  }

  @media only screen and (max-width: 1023px) {
    padding: 20px;
    width: 80%;
    height: 90%;
    display: block;
    overflow-y: scroll;
  }
`;

export const GameImageWrapper = styled.div`
  @media only screen and (max-width: 1023px) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 125px auto;
    gap: 20px;
  }
`;

export const GameImage = styled.div`
  width: 125px;
  height: 125px;
  background-image: url(${(p) => p.source});
  background-size: contain;
  border-radius: 11px;
`;

export const Downloads = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  margin-top: 30px;

  & > span {
    color: #2a2a2a;
    font-size: 16px;
  }

  & > :last-child {
    margin-top: 5px;
  }

  @media only screen and (max-width: 1023px) {
    margin-top: 18px;
  }
`;

export const Download = styled.div`
  width: 125px;
  height: 37px;
  background-image: url(${(p) => p.source});
  background-size: cover;
  cursor: none;

  @media only screen and (max-width: 1023px) {
    width: 100px;
    height: 30px;
  }
`;

export const MainInfoBox = styled.div`
  @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: portrait) {
    margin-left: 22px;
  }
`;

export const GameName = styled.h1`
  margin: 0;
  font-size: 35px;
  margin-bottom: 22px;
  @media only screen and (max-width: 1023px) {
    margin-bottom: 16px;
    margin-top: 16px;
  }
`;

export const GameDescription = styled.span`
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 26px;
  @media only screen and (max-width: 1023px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const GamePreviewWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: grid;
  grid-auto-flow: column;
  gap: 17px;
  margin-top: 55px;

  @media only screen and (max-width: 1023px) {
    margin-top: 16px;
    gap: 9px;
    width: 100%;
  }
`;

export const GamePreview = styled.img`
  height: 294px;
  width: 165px;
  @media only screen and (max-width: 1023px) {
    height: 162px;
    width: 91px;
  }
`;

export const GameDetails = styled.div`
  margin-top: 26px;
  margin-left: 77px;
  @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) 
  and (orientation: portrait) {
    margin-left: 22px;
  }
  @media only screen and (max-width: 1023px) {
    margin-left: 0px;
  }
`;

export const DetailHeading = styled.h3`
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 8px;
  @media only screen and (max-width: 767px) {
    margin-top: 16px;
    margin-bottom: 4px;
  }
`;

export const DetailDescription = styled.span`
  font-size: 14px;
  display: block;
`;

export const Press = styled.a`
  text-decoration: none;
  font-size: 14px;
  display: block;
  color: #ff4438;
  cursor: none;
`;

export const CloseButton = styled.div`
  background-image: url('assets/icons/close.svg');
  width: 42px;
  height: 42px;
  background-size: contain;
  position: absolute;
  right: 13px;
  top: 13px;
  transition: transform 0.2s ease-in;

  :hover {
    transform: rotate(180deg);
  }
`;
