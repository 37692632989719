import styled from 'styled-components';

export const DeveloperWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 27px;
  grid-template-columns: 68px 100%;
  margin-bottom: 54px;
  width: 300px;
  @media only screen and (max-width: 1440px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 12px;
    margin-top: 0px;
  }
  @media (orientation: portrait) and (max-height: 650px) {
    transform: scale(0.8) translateX(-37px);
    margin-bottom: 0;
  }
`;

export const DeveloperImage = styled.img`
  width: 86px;
  height: 86px;
  border-radius: 12px;
  border: 2px solid #000;
`;
export const DeveloperDescription = styled.div`
  display: grid;
  grid-auto-flow: row;
  margin-left: 27px;
`;
export const DeveloperName = styled.h3`
  font-family: 'Segma';
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
`;
export const DeveloperRole = styled.h4`
  font-family: 'Segma';
  font-size: 14px;
  font-weight: lighter;
  margin-top: 0;
  margin-bottom: 15px;
`;

export const SocialIcons = styled.div`
  margin-top: -5px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 14.5px;
  grid-template-columns: 20px 20px 20px;
`;

export const SocialIcon = styled.img`
  width: 20px;
  height: 20px;
`;
