import React from 'react';
import {
  SocialIcons,
  SocialIcon,
  DeveloperWrapper,
  DeveloperImage,
  DeveloperDescription,
  DeveloperName,
  DeveloperRole,
} from './Developer.components';

const Social = (props) => {
  // const { twitterUrl, instagramUrl, linkedUrl } = props;
  const { linkedIn, instagram, twitter } = props;
  const openPage = (page) => {
    window.open(page, '_blank');
  };
  return (
    <SocialIcons>
      {twitter && (
        <SocialIcon className="clickable" onClick={() => openPage(twitter)} src="assets/images/twitter.svg" />
      )}
      {instagram && (
        <SocialIcon className="clickable" onClick={() => openPage(instagram)} src="assets/images/instagram.svg" />
      )}
      {linkedIn && (
        <SocialIcon className="clickable" onClick={() => openPage(linkedIn)} src="assets/images/linkedin.svg" />
      )}
    </SocialIcons>
  );
};

export const Developer = (props) => {
  const { img, name, role, linkedIn, instagram, twitter } = props;

  return (
    <DeveloperWrapper>
      <DeveloperImage className="clickable" src={img} />
      <DeveloperDescription>
        <DeveloperName> {name} </DeveloperName>
        <DeveloperRole> {role} </DeveloperRole>
        <Social linkedIn={linkedIn} instagram={instagram} twitter={twitter} />
      </DeveloperDescription>
    </DeveloperWrapper>
  );
};
