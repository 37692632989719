import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useMedia from 'use-media';

export const useIs700Height = () =>
  useMedia({
    maxHeight: 700
  });

const Wrapper = styled.div`
  display: none;
  background-color: #efefef;
  width: 100vw;
  height: 100%;
  min-height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
  cursor: default;
  @media only screen and (max-height: 600px) and (orientation: landscape) {
    display: block;
  }
`;

const Logo = styled.div`
  width: 65px;
  height: 38px;
  position: fixed;
  top: 50px;
  left: 50px;
  background-image: url("assets/logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
`;

const Title = styled.h1`
  top: 105px;
  left: 50px;
  width: 100px;
  height: 24px;
  color: #2A2A2A;
  text-align: left;
  font: normal normal bold 20px/23px Segma;
  letter-spacing: 0px;
  opacity: 1;
  position: absolute;
`;

const Description = styled.p`
  top: 142px;
  left: 50px;
  width: 175px;
  height: 41px;
  text-align: left;
  font: normal normal medium 16px/22px Segma;
  letter-spacing: 0px;
  color: #2A2A2A;
  opacity: 0.36;
  position: absolute;
`;

const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  ${(p) => {
    if (p.type === 0) return 'margin: 0 54px -5px 0';
    else if (p.type === 2) return 'margin: 0 50px -4px 0';
    else return '';
  }}
`;

const MobileLandscape = (props) => {
  const [rand, setRand] = useState(0);
  const is700Height = useIs700Height();

  useEffect(() => {
    const resizeListener = () => {
      setRand(Math.floor(Math.random() * 3));
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [setRand]);

  const imageUrl = rand === 2 ? 'assets/images/xd/Adam_XD.png' : 
    rand === 1 ? 'assets/images/xd/Dawid_XD.png' : 'assets/images/xd/Tomek_XD.png';

  return (
    <Wrapper>
      {is700Height && (
        <div>
          <Logo />
          <Title>Seriously?</Title>
          <Description>Please turn the phone to vertical position</Description>
          <Image type={rand} src={imageUrl} />
        </div>
      )}
    </Wrapper>
  );
}

export default MobileLandscape;