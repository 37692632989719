import React from 'react';
import { MenuGrid, MenuItem, Email, MenuWrapper, AnimationMenu } from './menu.components';

const Menu = ({ index, hideMenu, setCurrentSection }) => {
  const selectAndClose = (choosenIndex) => {
    hideMenu();
    setCurrentSection(choosenIndex);
  };

  return (
    <>
      <MenuWrapper>
        <MenuGrid>
          <MenuItem className="clickable" active={index === 1} onClick={() => selectAndClose(1)}>
            News
          </MenuItem>
          <MenuItem className="clickable" active={index === 2} onClick={() => selectAndClose(2)}>
            Let's play
          </MenuItem>
          <MenuItem className="clickable" active={index === 3} onClick={() => selectAndClose(3)}>
            About us
          </MenuItem>
          <MenuItem className="clickable" active={index === 4} onClick={() => selectAndClose(4)}>
            Contact
          </MenuItem>
        </MenuGrid>
        <Email className="clickable email"><a href="mailto:hi@thethreelegends.com">Hi@thethreelegends.com</a></Email>

        <AnimationMenu className="clickable" />
      </MenuWrapper>
    </>
  );
};

export default Menu;
