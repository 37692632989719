import React from 'react';
import { Layout, CommonWrapper } from '../shared/styles';
import { Game } from './Game';
import { AnimationLetsPlay, GamesWrapper } from './Game.components';

const LetsPlay = (props) => {
  return (
    <CommonWrapper>
      <Layout className="layout">
        <GamesWrapper>
          <Game
            imgSrc={'assets/images/games/jumpOnce.png'}
            title={'Jumponce'}
            description={'Be fast, focused and calm. These are the main principles to become a champion.'}
            longDescription={
              'Reach the sky in this easy to learn - one tap - addictive game. Collect coins, discover our hidden areas, unlock some interesting characters and challenge your friends - can they jump higher than you?'
            }
            releaseAndroid={'XII.2020'}
            releaseIOS={'XII.2020'}
            onModalOpen={props.onModalOpen}
            disableCursor={props.disableCursor}
          />
        </GamesWrapper>
      </Layout>
      <AnimationLetsPlay className="clickable" />
    </CommonWrapper>
  );
};

export default LetsPlay;
