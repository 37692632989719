import styled from 'styled-components';

export const BigScreenWrapper = styled.div`
  width: 40%;
  height: 100vh;
  position: fixed;
  background: #fbfbfb;
  right: 0;
  font-family: 'Segma';
  padding: 140px 0 0 82px;
  z-index: 501;
`;

export const BigScreenTitle = styled.h4`
  font-size: 36px;
  margin: 0;
  width: 622px;
`;

export const BigScreenDescription = styled.p`
  color: #2a2a2a;
  font-size: 22px;
  width: 532px;
  opacity: 0.36;
`;

export const BigImageA = styled.div`
  background-image: url('assets/images/bigScreen/tomek-big.svg');
  background-size: contain;
  width: 340px;
  height: 250px;
  position: absolute;
  bottom: 120px;
  transform: scale(0.9);
`;
export const BigImageB = styled.div`
  background-image: url('assets/images/bigScreen/dawid-big.svg');
  background-size: contain;
  width: 200px;
  height: 250px;
  position: absolute;
  bottom: 120px;
  right: 40%;
`;
export const BigImageC = styled.div`
  background-image: url('assets/images/bigScreen/adam-big.svg');
  background-size: contain;
  width: 250px;
  height: 250px;
  position: absolute;
  bottom: 120px;
  right: 100px;
`;
