import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useModal from '../Modal/useModal';
import {
  GameImageWrapper,
  ModalWrapper,
  GameImage,
  Downloads,
  Download,
  MainInfoBox,
  GameName,
  GameDescription,
  GamePreviewWrapper,
  GamePreview,
  GameDetails,
  DetailHeading,
  DetailDescription,
  Press,
  CloseButton,
} from './GameDetailsModal.components';

const HandWrapper = styled.div`
  width: 220px;
  height: 280px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const HandInner = styled.div`
  width: 160px;
  height: 80px;
  position: absolute;
  top: 30px;
  margin: 0 calc(50% - 80px);
  z-index: 20;
`;

const Hand = styled.div`
  ${(p) => p.type === 1 && 'background-image: url(assets/hands/hand-01.svg)'};
  ${(p) => p.type === 2 && 'background-image: url(assets/hands/hand-02.svg)'};
  ${(p) => p.type === 3 && 'background-image: url(assets/hands/hand-03.svg)'};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 92px;
  height: 310px;
  position: absolute;
  right: 70px;
  bottom: -37px;
  transition: transform 200ms, bottom 200ms;
  transform-origin: bottom;
`;

const ButtonPlay = styled.div`
  background-color: #2a2a2a;
  color: #efefef;
  text-align: center;
  text-transform: uppercase;
  padding: 20px;
  width: 100px;
  border-radius: 11px;
  margin: 40px auto;
`;

const useHover = () => {
  const ref = useRef();
  const [hovered, setHovered] = useState(false);

  const enter = () => setHovered(true);
  const leave = () => setHovered(false);

  useEffect(() => {
    const current = ref.current;
    current.addEventListener('mouseenter', enter);
    current.addEventListener('mouseleave', leave);
    return () => {
      current.removeEventListener('mouseenter', enter);
      current.removeEventListener('mouseleave', leave);
    };
  }, [ref]);

  return [ref, hovered];
};

const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener('mousemove', setFromEvent);

    return () => {
      window.removeEventListener('mousemove', setFromEvent);
    };
  }, []);

  return position;
};

const usePosition = () => {
  const ref = useRef();
  const [position, setPosition] = useState({});

  const handleResize = () => {
    setPosition(ref.current.getBoundingClientRect());
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [ref, position];
};

let isActive = false;
let isRunning = false;
let showCaption = false;
let caughtTimeout = null;

const GrabHand = (props) => {
  const [outerRef, outerHovered] = useHover();
  const [innerRef, innerHovered] = useHover();
  const mousePos = useMousePosition();
  const [ref, position] = usePosition();
  const [buttonRef, buttonPosition] = usePosition();
  const [isCaught, setIsCaught] = useState(false);
  const [type, setType] = useState(-1);

  let wrapperStyle = { bottom: -350 };

  if (outerHovered) {
    const x = position.left + position.width * 0.5;
    const y = position.top + position.height * 0.5;
    const angle = Math.atan2(mousePos.x - x, -(mousePos.y - y)) * (180 / Math.PI);

    const rotation = Math.min(Math.max(parseInt(angle), -20), 20) * 1.5;

    let buttonPositionY = buttonPosition.top + buttonPosition.height;

    let bottom = -87 - (mousePos.y - buttonPositionY);

    if (!innerHovered || isCaught) {
      if (caughtTimeout != null) clearTimeout(caughtTimeout);
      bottom = -350;
      if (!isCaught && isActive) {
        isActive = false;
        showCaption = false;
      }
    } else if (!isCaught && !isActive) {
      isActive = true;
      isRunning = true;
      setType(Math.floor(Math.random() * 3) + 1);
      caughtTimeout = setTimeout(() => {
        showCaption = true;
        setIsCaught(true);
        props.disableCursor(true);
        setTimeout(() => {
          isRunning = false;
          setIsCaught(false);
          props.disableCursor(false);
        }, 2000);
      }, 400);
    } else if (isActive && !isCaught && !isRunning) {
      bottom = -350;
    }

    wrapperStyle = { transform: `rotate(${rotation}deg)`, bottom };
  }

  return (
    <HandWrapper ref={outerRef}>
      <ButtonPlay ref={buttonRef}>{showCaption && (innerHovered || isRunning) ? 'Nice try!' : 'Play!'}</ButtonPlay>
      <HandInner className="clickable" ref={innerRef} />
      <Hand ref={ref} style={wrapperStyle} type={type} />
    </HandWrapper>
  );
};

const GameDetailsModal = (props) => {
  const { closeModal } = useModal();
  const { title, imgSrc, longDescription, releaseAndroid, releaseIOS } = props;

  const openPage = (page) => {
    window.open(page, '_blank');
  };

  return (
    <ModalWrapper>
      <CloseButton
        className="clickable"
        onClick={() => {
          closeModal();
          props.onClose();
        }}
      />
      <GameImageWrapper>
        <GameImage source={imgSrc} />
        <Downloads>
          <span>Download on</span>
          <Download
            onClick={() => openPage('https://apps.apple.com/us/app/jumponce/id1542635643')}
            className="clickable"
            source={'assets/images/ios-store.svg'}
          />
          <Download
            onClick={() => openPage('https://play.google.com/store/apps/details?id=com.thethreelegends.Jumponce')}
            className="clickable"
            source={'assets/images/play-store.svg'}
          />
        </Downloads>
      </GameImageWrapper>

      <MainInfoBox>
        <GameName>{title}</GameName>
        <GameDescription>{longDescription}</GameDescription>

        <GamePreviewWrapper>
          <GamePreview src="assets/images/games/jumponce/Google Pixel 3 (0).png" />
          <GamePreview src="assets/images/games/jumponce/Google Pixel 3 (1).png" />
          <GamePreview src="assets/images/games/jumponce/Google Pixel 3 (2).png" />
          <GamePreview src="assets/images/games/jumponce/Google Pixel 3 (3).png" />
          <GamePreview src="assets/images/games/jumponce/Google Pixel 3 (4).png" />
          <GamePreview src="assets/images/games/jumponce/Google Pixel 3 (5).png" />
          <GamePreview src="assets/images/games/jumponce/Google Pixel 3 (6).png" />
          <GamePreview src="assets/images/games/jumponce/Google Pixel 3 (7).png" />
        </GamePreviewWrapper>
      </MainInfoBox>

      <GameDetails>
        <DetailHeading>DEVELOPER(S)</DetailHeading>
        <DetailDescription>The Three Legends</DetailDescription>

        <DetailHeading>PLATFORM(S)</DetailHeading>
        <DetailDescription>iOS ({releaseIOS})</DetailDescription>
        <DetailDescription>Android ({releaseAndroid})</DetailDescription>

        <DetailHeading>PRESS</DetailHeading>
        <Press className="clickable" href="#">
          coming soon
        </Press>
      </GameDetails>

      {/*window.innerWidth > 1200 && <GrabHand disableCursor={props.disableCursor} />*/}
    </ModalWrapper>
  );
};

export default GameDetailsModal;
