import React, { useCallback, useMemo, useState } from "react";
import ModalContainer from "./ModalContainer";
import ModalContext from "./ModalContext";

const ModalProvider = ({ children }) => {
  const [state, setState] = useState({
    isOpen: false,
    position: "absolute",
  });

  const contextValue = useMemo(
    () => ({
      openFixedModal(content, onBeforeClose) {
        setState({
          content,
          onBeforeClose,
          isOpen: true,
          position: "fixed",
        });
      },
      closeModal() {
        setState((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      },
    }),
    []
  );

  const handleClose = useCallback(
    (event) => {
      event.preventDefault();
      if (event.target !== event.currentTarget) {
        return;
      }

      const shouldCancel = state.onBeforeClose?.();
      if (!shouldCancel) {
        contextValue.closeModal();
      }
    },
    [contextValue, state]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <ModalContainer
        isOpen={state.isOpen}
        onBackgroundClick={handleClose}
        position={state.position}
      >
        {state.content}
      </ModalContainer>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
