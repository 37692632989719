import React from 'react';
import { IndicatorWrapper, IndicatorGrid, Marker, MarkerWrapper } from './Indicator.components';

export const Markers = ({ index, setCurrentSection, isMenuView }) => {
  return (
    <IndicatorWrapper>
      <IndicatorGrid>
        <MarkerWrapper
          className="clickable"
          onClick={() => setCurrentSection(1)}>
          <Marker
            isMenuView={isMenuView}
            active={index === 1}
          />
        </MarkerWrapper>
        <MarkerWrapper
          className="clickable"
          onClick={() => setCurrentSection(2)}>
          <Marker
            isMenuView={isMenuView}
            active={index === 2}
          />
        </MarkerWrapper>
        <MarkerWrapper
          className="clickable"
          onClick={() => setCurrentSection(3)}>
          <Marker
            isMenuView={isMenuView}
            active={index === 3}
          />
        </MarkerWrapper>
        <MarkerWrapper
          className="clickable"
          onClick={() => setCurrentSection(4)}>
          <Marker
            isMenuView={isMenuView}
            active={index === 4}
          />
        </MarkerWrapper>
      </IndicatorGrid>
    </IndicatorWrapper>
  );
};
