import React, { useEffect, useState } from 'react';
import { Heading } from './Heading/Heading';
import { News } from './News';
import { LetsPlay } from './LetsPlay';
import { AboutUs } from './AboutUs';
import { Contact } from './Contact';
import { Menu } from './Menu/';
import TextTransition, { presets } from 'react-text-transition';
import { CSSTransition } from 'react-transition-group';
import { Markers } from './Indicators/Indicator';
import '../../index.css';
import styled from 'styled-components';
import { WindupChildren } from 'windups';
import { useSwipeable } from 'react-swipeable';
import { useIsMobile, useIsOver2k, useIsTablet } from '../../helpers/useMedia';
import BigScreen from './BigScreen/BigScreen';
import Cursor from './Cursor/cursor';

const Logo = styled.div`
  width: 65px;
  height: 38px;
  position: fixed;
  top: 50px;
  left: 50px;
  background-image: ${(p) =>
    p.showMenu
      ? 'url("assets/close.svg")'
      : p.currentSection !== 0
      ? 'url("assets/ttl.svg")'
      : 'url("assets/logo.svg")'};
  background-size: contain;
  background-repeat: no-repeat;
  transition: background-image 0.4s ease-out;
  z-index: 2;

  @media only screen and (max-width: 1440px) {
    top: 30px;
    left: 27px;
  }

  @media only screen and (max-width: 767px) {
    top: 30px;
    left: 30px;
  }
`;
export const HeaderSimple = styled.h1`
  position: fixed;
  color: #2a2a2a;
  letter-spacing: 0px;
  font-weight: bold;
  font-size: 115px;
  font-family: 'Segma';
  text-align: left;
  margin: 0px;
  padding-left: 130px;
  padding-top: 112px;

  @media only screen and (max-width: 1440px) {
    padding-top: 81px;
    padding-left: 81px;
    font-size: 70px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 101px;
    padding-left: 30px;
    font-size: 49px;
    width: 270px;
  }

  @media only screen and (max-width: 400px) {
    padding-top: 80px;
    font-size: 45px;
  }
`;
const Wrapper = styled.div`
  &::after{
    position: absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content: url('assets/images/animation_news.gif') url('assets/images/animation_letsPlay.gif') url("assets/images/animation_about.gif") url('assets/images/animation_contact.gif');
  }
`;

const SocialWrapper = styled.div`
  bottom: 0;
  right: ${(p) => p.isOver2k ? 'calc(40% + 20px)' : '20px'};
  position: absolute;
  width: 200px;
  height: 100px;

  > div {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 767px) {
    right: 0;
    height: 50px;
    > div {
      margin-bottom: 20px;
    }
  }
`;

const Social = styled.div`
  background-image: ${(p) =>
    p.showMenu ? 'url("assets/images/social-eye-white.svg")' : 'url("assets/images/social-eye.svg")'};
  background-size: contain;
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 2px;
  right: ${(p) => (p.move ? '110px' : '0')};
  z-index: 2;
  transition: right 0.2s ease-in;

  @media only screen and (min-width: 2048px) {
    right: ${(p) => (p.move ? 'calc(44% + 130px)' : 'calc(44% + 20px)')};
  }

  @media only screen and (max-width: 767px) {
    right: ${(p) => (p.move ? '90px' : '20px')};
    bottom: 0px;
  }
`;

const SocialIcons = styled.div`
  height: 28px;
  position: absolute;
  bottom: 3%;
  right: 30px;
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  align-items: center;

  @media only screen and (max-width: 767px) {
    right: 10px;
  }

  @media only screen and (min-width: 2048px) {
    right: calc(44% + 50px);
  }
`;

const SocialIcon = styled.div`
  width: 20px;
  height: 20px;
  margin: 5px;
  transition: transform 0.2s ease-in;
  :hover {
    transform: scale(1.2);
  }
`;

let scrollTimeout = null;
let scrollTryTimeout = null;
let scrollTry = 0;
let canWindowScroll = true;

export const MainPageComponent = () => {
  const [canScroll, setCanScroll] = useState(true);
  const [disableScroll, setDisableScroll] = useState(false);
  const [direction, setDirection] = useState('down');
  const [showMenu, setShowMenu] = useState(false);
  const [showSocial, setShowSocial] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [twitterHovered, setTwitterHovered] = useState(false);
  const [youtubeHovered, setYoutubeHovered] = useState(false);
  const [cursorDisabled, setCursorDisabled] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isOver2k = useIsOver2k();

  const handlers = useSwipeable({
    onSwipedDown: () => {
      if (!disableScroll && canScroll && !showMenu && (isMobile || isTablet)) {
        setCanScroll(false);
        if (currentSection > 0) {
          setCurrentSection(currentSection - 1);
          setDirection('up');
        }
        setCanScroll(false);
        setTimeout(() => {
          setCanScroll(true);
        }, 800);
      }
    },
    onSwipedUp: () => {
      if (!disableScroll && canScroll && !showMenu && (isMobile || isTablet)) {
        setCanScroll(false);
        if (currentSection < 4) {
          setCurrentSection(currentSection + 1);
          setDirection('down');
        }
        setCanScroll(false);
        setTimeout(() => {
          setCanScroll(true);
        }, 800);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const openPage = (page) => {
    window.open(page, '_blank');
  };
  const headings = ['We create games', 'News', "Let's play", 'About us', 'Contact'];

  useEffect(() => {
    window.onwheel = (e) => {
      if (!disableScroll) {
      if (canWindowScroll && !showMenu) {
        if (scrollTryTimeout) clearTimeout(scrollTryTimeout);
          canWindowScroll = false;
          scrollTry = 0;
          if (e.deltaY <= 0) {
            if (currentSection > 0) {
              setCurrentSection(currentSection - 1);
              setDirection('up');
            }
          } else if (e.deltaY > 0) {
            if (currentSection < 4) {
              setCurrentSection(currentSection + 1);
              setDirection('down');
            }
          }
          scrollTimeout = setTimeout(() => {
            canWindowScroll = true;
            scrollTry = 0;
          }, 800);
        } else if (scrollTry === 5) {
          if (scrollTimeout) clearTimeout(scrollTimeout);
          canWindowScroll = true;
          scrollTry = 0;
        } else {
          scrollTry++;
          if (scrollTryTimeout) clearTimeout(scrollTryTimeout);
          scrollTryTimeout = setTimeout(() => {
            scrollTry = 0;
          }, 700);
        }
      }
    };
  }, [currentSection, showMenu, isOver2k, disableScroll]);

  const toggleTwitterHover = () => setTwitterHovered(!twitterHovered);
  const toggleYoutubeHover = () => setYoutubeHovered(!youtubeHovered);

  const onModalOpen = (isVisible) => {
    setDisableScroll(isVisible);
  }

  const disableCursor = isDisabled => setCursorDisabled(isDisabled);

  return (
    <div {...handlers}>
      <Wrapper>
        <Logo
          className="clickable"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          currentSection={currentSection}
          showMenu={showMenu}
        />
        <CSSTransition in={currentSection > 0} timeout={300} classNames="markers" unmountOnExit>
          <Markers index={currentSection} setCurrentSection={setCurrentSection} isMenuView={showMenu} />
        </CSSTransition>
        <HeaderSimple>
          <TextTransition text={headings[currentSection]} spring={presets.gentle} noOverflow direction={direction} />
        </HeaderSimple>

        <CSSTransition in={currentSection === 0} timeout={300} classNames="alert" unmountOnExit>
          <WindupChildren>
            <Heading />
          </WindupChildren>
        </CSSTransition>
        <CSSTransition in={currentSection === 1} timeout={300} classNames="alert" unmountOnExit>
          <News />
        </CSSTransition>
        <CSSTransition in={currentSection === 2} timeout={300} classNames="alert" unmountOnExit>
          <LetsPlay onModalOpen={onModalOpen} disableCursor={disableCursor} />
        </CSSTransition>
        <CSSTransition in={currentSection === 3} timeout={300} classNames="alert" unmountOnExit>
          <AboutUs />
        </CSSTransition>
        <CSSTransition in={currentSection === 4} timeout={300} classNames="alert" unmountOnExit>
          <Contact />
        </CSSTransition>
        <CSSTransition in={showMenu} timeout={300} classNames="alert" unmountOnExit>
          <Menu index={currentSection} hideMenu={() => setShowMenu(false)} setCurrentSection={setCurrentSection} />
        </CSSTransition>

        <SocialWrapper
          onMouseLeave={() => {
            if (showSocial && !isMobile && !isTablet) setShowSocial(false)
          }}
          isOver2k={isOver2k}
          >
          <Social
            className="clickable"
            move={showSocial}
            showMenu={showMenu}
            onClick={() => setShowSocial(!showSocial)}
            onMouseEnter={() => {
              if (!showSocial && !isMobile && !isTablet) setShowSocial(true)
            }}
          />

          <CSSTransition in={showSocial} timeout={300} classNames="social" unmountOnExit={false} className="social-width">
            <SocialIcons>
              <SocialIcon
                className={twitterHovered ? 'clickable twitter' : showMenu ? 'clickable twitter-white' : 'clickable twitter-black'}
                onClick={() => openPage('https://twitter.com/LegendsThree')}
                onMouseEnter={toggleTwitterHover}
                onMouseLeave={toggleTwitterHover}
              ></SocialIcon>
              <SocialIcon
                className={youtubeHovered ? 'clickable youtube' : showMenu ? 'clickable youtube-white' : 'clickable youtube-black'}
                onClick={() => openPage('https://twitter.com/LegendsThree')}
                onMouseEnter={toggleYoutubeHover}
                onMouseLeave={toggleYoutubeHover}
              ></SocialIcon>
            </SocialIcons>
          </CSSTransition>
        </SocialWrapper>
      </Wrapper>
      {isOver2k && <BigScreen />}
      {!isMobile && !isTablet && <Cursor page={currentSection} isMenu={showMenu} disableScroll={disableScroll} disableCursor={cursorDisabled} />}
    </div>
  );
};
