import useMedia from 'use-media';

export const useIsMobile = () => useMedia({ maxWidth: '880px' });
const BREAKPOINTS = {
  MOBILE_SMALL: {
    max: 479.98,
  },
  MOBILE: {
    min: 480,
    max: 767.98,
  },
  TABLET_SMALL: {
    min: 768,
    max: 899.98,
  },
  TABLET: {
    min: 768,
    max: 1199.98,
  },
  TABLET_LARGE: {
    min: 900,
    max: 1199.98,
  },
  LAPTOP: {
    min: 1200,
    max: 1440.98,
  },
  LAPTOP_LARGE: {
    min: 1440,
  },
  OVER_2K: {
    min: 2000,
    max: 9999,
  },
  OVER_22K: {
    min: 2000,
    max: 2499,
  },
  OVER_25K: {
    min: 2500,
    max: 2799,
  },
  OVER_28K: {
    min: 2800,
    max: 9999,
  },
};

export const useIsTablet = () =>
  useMedia({
    minWidth: BREAKPOINTS.TABLET.min,
    maxWidth: BREAKPOINTS.TABLET.max,
  });

export const useIsTabletDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.TABLET.max,
  });

export const useIsMobileDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.MOBILE.max,
  });

export const useIsOver2k = () =>
  useMedia({
    minWidth: BREAKPOINTS.OVER_2K.min,
    maxWidth: BREAKPOINTS.OVER_2K.max,
  });
export const useIsOver22k = () =>
  useMedia({
    minWidth: BREAKPOINTS.OVER_22K.min,
    maxWidth: BREAKPOINTS.OVER_22K.max,
  });

export const useIsOver25k = () =>
  useMedia({
    minWidth: BREAKPOINTS.OVER_25K.min,
    maxWidth: BREAKPOINTS.OVER_25K.max,
  });

export const useIsOver28k = () =>
  useMedia({
    minWidth: BREAKPOINTS.OVER_28K.min,
    maxWidth: BREAKPOINTS.OVER_28K.max,
  });
