import React from 'react';
import { useIsOver22k, useIsOver25k, useIsOver28k } from '../../../helpers/useMedia';
import {
  BigScreenWrapper,
  BigScreenTitle,
  BigScreenDescription,
  BigImageA,
  BigImageB,
  BigImageC,
} from './BigScreen.components';

const BigScreen = (props) => {
  const isOver22k = useIsOver22k();
  const isOver25k = useIsOver25k();
  const isOver28k = useIsOver28k();

  return (
    <BigScreenWrapper>
      <BigScreenTitle>Congratulations on the big screen and such a high resolution!</BigScreenTitle>
      <BigScreenDescription>
        We didn't have time to make a website of this size for you because we make nice games at that time.
      </BigScreenDescription>
      {(isOver22k || isOver25k || isOver28k) && <BigImageA />}
      {(isOver25k || isOver28k) && <BigImageB />}
      {isOver28k && <BigImageC />}
    </BigScreenWrapper>
  );
};

export default BigScreen;
