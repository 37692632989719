import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

const CleanUp = styled.div`
  transform: translateZ(0);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  ${(p) => p.isMenu ? 'background-color: #2a2a2a;' : 'background-color: #efefef'}
`;

const CursorEl = styled.div`
  width: 12px;
  height: 12px;
  background-size: contain;
  background-image: url('assets/cursors/standard.svg');
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  transition: 0.1s ease-in;
  transition-property: opacity, background-image, transform;
  ${(p) => p.mouseDown && "background-image: url('assets/cursors/click.svg');"}
  ${(p) => p.hidden && 'display:none;'}
  ${(p) => p.hovered && "background-image: url('assets/cursors/hover.svg');"}
  ${(p) => p.hovered && 'transform: translate(-50%, -50%) scale(1.8)'}
  ${(p) => p.mouseDown && 'transform: translate(-50%, -50%) scale(2)'}
`;

const CursorElWhite = styled.div`
  width: 12px;
  height: 12px;
  background-size: contain;
  background-image: url('assets/cursors/standard-white.svg');
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  transition: 0.1s ease-in;
  transition-property: opacity, background-image, transform;
  ${(p) => p.mouseDown && "background-image: url('assets/cursors/click-white.svg');"}
  ${(p) => p.hidden && 'display:none;'}
  ${(p) => p.hovered && "background-image: url('assets/cursors/hover-white.svg');"}
  ${(p) => p.hovered && 'transform: translate(-50%, -50%) scale(1.8)'}
  ${(p) => p.mouseDown && 'transform: translate(-50%, -50%) scale(2)'}
`;

let cleanUpTimeout = null;
let mouseUpTimeout = null;

const Cursor = (props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [mouseDown, setMouseDown] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [cleanUp, setCleanUp] = useState(true);

  useEffect(() => {
    addEventListeners();
    handleLinkHoverEvents();
    setIsMenu(props.isMenu);
    return () => {
      removeEventListeners();
      removeLinkHoverEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page, props.isMenu, props.disableScroll]);

  const addEventListeners = () => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mouseenter', onMouseEnter);
    document.addEventListener('mouseleave', onMouseLeave);
  };

  const handleLinkHoverEvents = () => {
    document.querySelectorAll('.clickable').forEach((el) => {
      el.addEventListener('mouseover', onOverLinkHovered);
      el.addEventListener('mouseout', onOutLinkHovered);
    });
  };

  const removeLinkHoverEvents = () => {
    document.querySelectorAll('.clickable').forEach((el) => {
      el.removeEventListener('mouseover', onOverLinkHovered);
      el.removeEventListener('mouseout', onOutLinkHovered);
    });
  };

  const removeEventListeners = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('mouseenter', onMouseEnter);
    document.removeEventListener('mouseleave', onMouseLeave);
  };

  const onOverLinkHovered = (e) => {
    setLinkHovered(true);
  }

  const onOutLinkHovered = (e) => {
    setLinkHovered(false);
  }

  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const onMouseDown = (e) => {
    setMouseDown(true);
    cleanUpBG(e);
  };

  const onMouseUp = (e) => {
    if (mouseUpTimeout) clearTimeout(mouseUpTimeout);
    mouseUpTimeout = setTimeout(() => {
      setMouseDown(false);
      cleanUpBG(e);
    }, [300]);
  };

  const onMouseLeave = () => {
    setHidden(true);
  };

  const onMouseEnter = () => {
    setHidden(false);
  };

  const cleanUpBG = (e) => {
    if (!e.target.classList.contains('clickable')) {
      if (cleanUpTimeout) clearTimeout(cleanUpTimeout);
      cleanUpTimeout = setTimeout(() => {
        setCleanUp(true);
        cleanUpTimeout = setTimeout(() => {
          setCleanUp(false);
        }, 100);
      }, 100);
    }
  }

  return (
    <>
      {cleanUp && (<CleanUp isMenu={isMenu} />)}
      {!isMenu && !props.disableCursor && (
        <CursorEl
          mouseDown={mouseDown}
          hidden={hidden}
          hovered={linkHovered}
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
          }}
        />
      )}
      {isMenu && !props.disableCursor && (
        <CursorElWhite
          mouseDown={mouseDown}
          hidden={hidden}
          hovered={linkHovered}
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
          }}
        />
      )}
    </>
  );
};

export default Cursor;
