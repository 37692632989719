import styled from 'styled-components';

export const IndicatorWrapper = styled.div`
  position: absolute;
  right: 73px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 200;

  @media only screen and (min-width: 2048px) {
    right: calc(44% + 28px);
  }

  @media only screen and (max-width: 1440px) {
    top: 23px;
    transform: none;
    right: 28px;
  }

  @media only screen and (max-width: 767px) {
    top: 30px;
    transform: none;
    right: 12px;
  }
`;
export const IndicatorGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  align-content: center;

  @media only screen and (max-width: 1440px) {
    grid-gap: 5px;
  }
`;

export const MarkerWrapper = styled.div`
  width: 30px;
  height: 30px;
  z-index: 500000;
  display: flex;
  justify-content: center;
`;

export const Marker = styled.div`
  margin: auto 0;
  transition: background-color 0.2s ease-in;
  width: ${(p) => (p.active ? '8px;' : '5px;')};
  height: ${(p) => (p.active ? '8px;' : '5px;')};
  border-radius: 50%;
  background-color: ${(p) => (p.active ? '#ff4438;' : p.isMenuView ? '#e4e4e4;' : '#2a2a2a;')};
  z-index: 500000;
  @media only screen and (max-width: 1440px) {
    width: 5px;
    height: 5px;
  }
`;
