import React from 'react';
import {
  GameWrapper,
  GamePostWrapper,
  GameImage,
  GameContent,
  GameTitle,
  GameDescription,
  DownloadBox,
  StoreButtons,
  StoreButton,
  OuterWrapper,
  More,
  StoreButtonWrapper,
} from './Game.components';
import useModal from '../../shared/Modal/useModal';
import { useCallback } from 'react';
import GameDetailsModal from '../../shared/Modals/GameDetailsModal';
import { useIsMobile, useIsTablet } from '../../../helpers/useMedia';

export const Game = (props) => {
  const { title, description, imgSrc, longDescription, releaseAndroid, releaseIOS, onModalOpen, disableCursor } = props;

  const { openFixedModal } = useModal();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const openAddMemberModal = useCallback(() => {
    openFixedModal(
      <GameDetailsModal
        title={title}
        description={description}
        imgSrc={imgSrc}
        longDescription={longDescription}
        releaseAndroid={releaseAndroid}
        releaseIOS={releaseIOS}
        onClose={() => {
          onModalOpen(false);
        }}
        disableCursor={disableCursor}
      />
    );
    onModalOpen(true);
  }, [
    openFixedModal,
    title,
    description,
    imgSrc,
    longDescription,
    releaseIOS,
    releaseAndroid,
    onModalOpen,
    disableCursor,
  ]);

  const DownloadSection = (
    <DownloadBox>
      Download from
      <StoreButtons>
        <StoreButtonWrapper
          href="https://apps.apple.com/us/app/jumponce/id1542635643"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StoreButton className="clickable" src="assets/images/ios-store.svg" />
        </StoreButtonWrapper>
        <StoreButtonWrapper
          href="https://play.google.com/store/apps/details?id=com.thethreelegends.Jumponce"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StoreButton className="clickable" src="assets/images/play-store.svg" />
        </StoreButtonWrapper>
      </StoreButtons>
    </DownloadBox>
  );

  return (
    <GameWrapper>
      <OuterWrapper>
        <GamePostWrapper>
          <GameImage className="clickable" src={imgSrc} />
          <GameContent>
            <GameTitle>{title}</GameTitle>
            {!isTablet && !isMobile && DownloadSection}
          </GameContent>
        </GamePostWrapper>
        <GameDescription>{description}</GameDescription>
        <More className="clickable" onClick={openAddMemberModal}>
          more
        </More>
        {(isTablet || isMobile) && DownloadSection}
      </OuterWrapper>
    </GameWrapper>
  );
};
