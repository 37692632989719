import styled from 'styled-components';

export const ContactDescription = styled.p`
  width: 550px;
  font-family: 'Segma';
  font-size: 16px;
  margin: 0;
  margin-top: 50px;
  line-height: 1.5;
  margin-bottom: 50px;

  @media only screen and (max-width: 1440px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 767px) {
    width: 90%;
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 35px;
  }

  @media only screen and (max-width: 400px) {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

export const Mail = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 45px 100%;
  grid-gap: 17.5px;
  @media only screen and (max-width: 767px) {
    grid-gap: 10px;
  }
`;
export const MailArrow = styled.img`
  width: 45px;
  height: 22.5px;
  display: flex;
  align-self: center;
`;
export const MailAddress = styled.h2`
  width: 400px;
  font-family: 'Segma';
  font-size: 35px;
  margin: 0;

  @media only screen and (max-width: 1440px) {
    font-size: 27px;
  }

  @media only screen and (max-width: 767px) {
    width: 200px;
    font-size: 20px;
  }

  @media only screen and (max-width: 400px) {
    width: 400px;
    font-size: 16px;
  }
`;

export const AnimationContact = styled.div`
  background-image: url('assets/images/animation_contact.gif');
  width: 386px;
  height: 400px;
  background-size: cover;
  position: absolute;
  bottom: 180px;
  right: 16%;

  @media only screen and (max-width: 1300px) {
    width: 276px;
    height: 250px;
    bottom: 25%;
    right: 8%;
  }

  @media only screen and (max-width: 1023px) {
    width: 272px;
    height: 261px;
    right: 50%;
    transform: translateX(50%);
    bottom: 5%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
