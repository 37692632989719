import React from 'react';
import { Layout, CommonWrapper } from '../shared/styles';
import { ContactDescription, Mail, MailArrow, MailAddress, AnimationContact } from './Contact.components';

const Contact = (props) => {
  //useQuery etc
  // posortowac po dacie / kategorii
  // map na komponenty Youtube / Twitter
  return (
    <CommonWrapper>
      <Layout className="layout">
        <ContactDescription>
          Do you want to find out what we are currently working on, are you looking for a job or want to learn something
          new? Stay in touch with us.
        </ContactDescription>

        <Mail>
          <MailArrow src="assets/images/contact/arrow.svg" />
          <MailAddress className="email"><a className="clickable" href="mailto:hi@thethreelegends.com">Hi@thethreelegends.com</a></MailAddress>
        </Mail>
      </Layout>
      <AnimationContact className="clickable" />
    </CommonWrapper>
  );
};
export default Contact;
