import React, { useState, useEffect } from 'react';
import {
  Headers,
  HeaderWithBackground,
  ScrollButton,
  MainAnimationA,
  MainAnimationB,
  MainAnimationC,
  SlideDown,
  Dot,
} from './Heading.components';
import { CommonWrapper } from '../shared/styles';
import { useWindupString } from 'windups';
export const Heading = (props) => {
  const [intro] = useWindupString('for fun.');

  const [currentAnimation, setCurrentAnimation] = useState(0);
  const animations = [
    <MainAnimationA className="clickable" />,
    <MainAnimationB className="clickable" />,
    <MainAnimationC className="clickable" />,
  ];

  useEffect(() => {
    setCurrentAnimation([Math.floor(Math.random() * animations.length)]);
  }, [animations.length]);

  return (
    <CommonWrapper className="layout">
      <Headers>
        <HeaderWithBackground>{intro}</HeaderWithBackground>
      </Headers>
      <ScrollButton>
        <SlideDown>
          <Dot />
        </SlideDown>
        scroll down
      </ScrollButton>
      {animations[currentAnimation]}
    </CommonWrapper>
  );
};
