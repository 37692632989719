import React, { useState } from "react";
import {
  StandardNewsWrapper,
  StandardNewsTitle,
  ReadMore,
  Wrapper,
  Date,
  StandardNewsDescription,
} from "./StandardNews.components";
import { useIsMobile } from "../../../../helpers/useMedia";

const StandardNews = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { title, description, date } = props;
  const isMobile = useIsMobile();
  const trimmingInterval = isMobile ? 100 : 200;
  return (
    <Wrapper>
      <Date>{date}</Date>
      <StandardNewsWrapper>
        <StandardNewsTitle>{title}</StandardNewsTitle>
        <StandardNewsDescription>
        {isOpen ? description : description.slice(0, trimmingInterval) + (description.length > trimmingInterval ? '...' : '')}
        {description.length > trimmingInterval && (
          <ReadMore onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? " show less" : " read more"}
          </ReadMore>
        )}
        </StandardNewsDescription>
      </StandardNewsWrapper>
    </Wrapper>
  );
};

export default StandardNews;
