import styled from 'styled-components';

export const AnimationNews = styled.div`
  background-image: url('assets/images/animation_news.gif');
  width: 386px;
  height: 400px;
  background-size: cover;
  position: absolute;
  bottom: 180px;
  right: calc(16% + 1px);

  @media only screen and (max-width: 1300px) {
    width: 276px;
    height: 250px;
    bottom: 25%;
    right: 8%;
  }
  @media only screen and (max-width: 1023px) {
    width: 272px;
    height: 261px;
    right: 50%;
    transform: translateX(50%);
    bottom: 5%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
