import React from "react";
import { Transition } from "react-transition-group";
import {
  Background,
  Container,
  MODAL_TRANSITION_TIME_MS,
} from "./ModalContainer.components";

const forceReflow = (node) => node.offsetHeight;

const ModalContainer = ({ children, isOpen, position, onBackgroundClick }) => {
  return (
    <Transition
      timeout={MODAL_TRANSITION_TIME_MS}
      mountOnEnter={true}
      unmountOnExit={true}
      appear={true}
      in={isOpen}
      onEnter={forceReflow}
    >
      {(status) => (
        <>
          <Background status={status} onClick={onBackgroundClick} />
          <Container
            status={status}
            onClick={onBackgroundClick}
            position={position}
          >
            {children && <children.type {...children.props} status={status} />}
          </Container>
        </>
      )}
    </Transition>
  );
};

export default ModalContainer;
