import styled from 'styled-components';

export const MenuWrapper = styled.section`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2a2a2a;
  width: 60%;
  @media only screen and (max-width: 2048px) {
    width: 100%;
  }
`;

export const MenuGrid = styled.div`
  margin-left: 133px;
  margin-top: 133px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 25px;

  @media only screen and (max-width: 1440px) {
    margin-left: 75px;
    margin-top: 81px;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 32px;
    margin-top: 102px;
  }
`;

export const MenuItem = styled.h1`
  font-size: 80px;
  font-family: 'Segma';
  font-weight: bold;
  width: 400px;
  margin: 0;
  color: ${(p) => (p.active ? '#ff4438;' : '#e4e4e4;')};
  transition: color 0.2s ease-in;
  &:hover {
    color: #ff4438 !important;
  }
  @media only screen and (max-width: 1440px) {
    font-size: 70px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 48px;
  }
`;

export const Email = styled.span`
  position: absolute;
  left: 133px;
  bottom: 40px;
  font-size: 30px;
  color: #e4e4e4 !important;
  font-family: 'Segma';
  font-weight: bold;

  @media only screen and (max-width: 1440px) {
    left: 75px;
  }
  @media only screen and (max-width: 767px) {
    left: 32px;
    font-size: 14px;
  }
`;

export const AnimationMenu = styled.div`
  background-image: url('assets/images/animation_menu.gif');
  width: 386px;
  height: 400px;
  background-size: cover;
  position: absolute;
  bottom: 180px;
  right: 16%;

  @media only screen and (max-width: 1440px) {
    bottom: 20%;
    right: 4%;
  }

  @media only screen and (max-width: 1023px) {
    right: 50%;
    transform: translateX(50%);
    bottom: 5%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
