import styled from "styled-components";

export const CommonWrapper = styled.section`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 60%;
  @media only screen and (max-width: 2048px) {
    width: 100%;
  }
`;

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #efefef;
`;
export const Layout = styled.div`
  padding: 252px 0 0 129px;

  @media only screen and (max-width: 1440px) {
    padding: 160px 0 0 78px;
  }

  @media only screen and (max-width: 767px) {
    padding: 170px 0 0 30px;
  }

  @media only screen and (max-width: 400px) {
    padding: 140px 0 0 30px;
  }
`;

export const HeaderSimple = styled.h1`
  color: #2a2a2a;
  letter-spacing: 0px;
  font-weight: bold;
  font-size: 115px;
  font-family: "Segma";
  text-align: left;
  margin: 0px;
  padding: 0px;
  width: 700px;
`;
