import { createContext } from "react";

const ModalContext = createContext({
  openFixedModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
  closeModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
});

export default ModalContext;
