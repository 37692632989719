import styled from "styled-components";
import { HeaderSimple } from "../shared/styles";

export const Headers = styled.div`
  padding-left: 130px;
  padding-top: 251px;

  @media only screen and (max-width: 1440px) {
    padding-left: 81px;
    padding-top: 160px;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 26px;
    padding-top: 230px;
  }

  @media only screen and (max-width: 400px) {
    padding-top: 210px;
  }
`;

export const HeaderWithBackground = styled(HeaderSimple)`
  color: #efefef;
  background-color: #2a2a2a;
  border-radius: 11px;
  padding: 10px 25px;
  width: 410px;

  @media only screen and (max-width: 1440px) {
    width: 284px;
    font-size: 70px;
    padding: 10px;
  }

  @media only screen and (max-width: 767px) {
    width: 193px;
    font-size: 49px;
    padding: 10px;
  }
`;

export const ScrollButton = styled.button`
  width: 133px;
  height: 44px;
  background: none;
  border: 1.5px solid #2a2a2a;
  border-radius: 7px;
  font-weight: bold;
  position: absolute;
  bottom: 131px;
  left: 50%;
  transform: translateX(-50%);
  cursor: none;

  @media only screen and (max-width: 1440px) {
    bottom: 91px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 8px;
    bottom: 70px;
    width: 85px;
    height: 28px;
  }
`;

export const MainAnimationA = styled.div`
  background-image: url("assets/images/mainPage/main_1.gif");
  width: 190px;
  height: 320px;
  background-size: cover;
  position: absolute;
  bottom: 116px;
  left: 48%;

  @media only screen and (max-width: 1440px) {
    bottom: 76px;
    left: 47%;
  }

  @media only screen and (max-width: 767px) {
    width: 120px;
    height: 200px;
    bottom: 60px;
    left: 44%;
  }
`;

export const MainAnimationB = styled.div`
  background-image: url("assets/images/mainPage/main_2.gif");
  width: 220px;
  height: 320px;
  background-size: cover;
  position: absolute;
  bottom: 40px;
  left: 38%;

  @media only screen and (max-width: 1440px) {
    left: 34%;
    bottom: 0px;
  }

  @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: portrait) {
    left: 22%;
    bottom: 0px;
  }

  @media only screen and (max-width: 767px) {
    width: 150px;
    height: 200px;
    bottom: 10px;
    left: 22%;
  }

  @media only screen and (max-width: 400px) {
    left: 11%;
  }
`;

export const MainAnimationC = styled.div`
  background-image: url("assets/images/mainPage/main_3.gif");
  width: 320px;
  height: 320px;
  background-size: cover;
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (max-width: 1440px) {
    bottom: 100px;
  }

  @media only screen and (max-width: 767px) {
    width: 200px;
    height: 200px;
    bottom: 70px;
  }
`;

export const SlideDown = styled.span`
  border-left: 2px solid #2a2a2a;
  margin-right: 15px;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 14px;
  background: #ff4438;
  position: absolute;
  left: 16.4px;
  top: 6.5px;
  animation-name: slide;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;

  @media only screen and (max-width: 767px) {
    width: 4px;
    height: 4px;
    left: 9px;
    animation-name: slideMobile;
  }
`;
