import React from "react";
import { MainPageComponent } from "./components/mainPage/mainPage";
import "./App.scss";
import "./index.css";
import ModalProvider from "./components/shared/Modal/ModalProvider";
import MobileLandscape from './components/MobileLandscape/MobileLandscape';

function App() {
  return (
    <div>
      <div>
        <ModalProvider>
          <MainPageComponent />
        </ModalProvider>
      </div>
      <MobileLandscape />
    </div>
  );
}

export default App;
