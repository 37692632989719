import styled from "styled-components";

export const StandardNewsWrapper = styled.div`
  padding: 24px 35px;
  transition: height 1s ease-in;
  color: #2a2a2a;
  background: #fff;
  border-radius: 11px;
  width: 625px;
  height: auto;
  flex: 1;
  transition: transform 1s ease-in, flex 0.3s ease-in;

  @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: portrait) {
    width: calc(100% / 1.3);
  }

  @media only screen and (max-width: 768px) {
    width: 65%;
  }

  @media only screen and (max-width: 400px) {
    padding: 10px 10px;
    width: calc(100% - 80px);
  }

  margin-top: 12px;
`;
export const StandardNewsTitle = styled.h5`
  font-family: "Segma";
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 0 0 12px 0;

  @media only screen and (max-width: 400px) {
    padding-bottom: 0;
  }
`;

export const StandardNewsDescription = styled.p`
  font-family: "Segma";
  font-size: 16px;
  margin: 10px 0 0;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

export const ReadMore = styled.span`
  color: #ff4438;
`;

export const Wrapper = styled.div`
  margin-top: 50px;

  @media only screen and (max-width: 1440px) {
    margin-top: 20px;
  }
  
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const Date = styled.span`
  margin-left: 15px;

  font-family: "Segma";
  font-size: 16px;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
