import React from 'react';
import { Layout, CommonWrapper } from '../shared/styles';
import { AboutDescription, AnimationAbout } from './AboutUs.components';
import { Developer } from './Developer';

const AboutUs = (props) => {
  //useQuery etc
  // posortowac po dacie / kategorii
  // map na komponenty Youtube / Twitter
  return (
    <CommonWrapper>
      <Layout className="layout">
        <AboutDescription>
          After completing one of the projects from work, three teammates decided to begin their adventure with creating
          games for fun.
        </AboutDescription>
        <Developer
          img="assets/images/about/adam.png"
          name="Adam Małyszko"
          role="Developer"
          linkedIn="https://www.linkedin.com/in/adam-ma%C5%82yszko/"
          twitter="https://twitter.com/xol90"
        />
        <Developer
          img="assets/images/about/dawid.png"
          name="Dawid Krakowczyk"
          role="Developer"
          linkedIn="https://www.linkedin.com/in/dkrakowczyk/"
          twitter="https://twitter.com/_builttofall"
        />
        <Developer
          img="assets/images/about/tomek.png"
          name="Tomasz Dubiel"
          role="Designer"
          linkedIn="https://www.linkedin.com/in/tomasz-dubiel-243161101/"
          twitter="https://twitter.com/oneofthelegends"
        />
      </Layout>
      <AnimationAbout className="clickable" />
    </CommonWrapper>
  );
};

export default AboutUs;
