import React from 'react';
import { Layout, CommonWrapper } from '../shared/styles';
import { AnimationNews } from './News.components';
import StandardNews from './Standard/StandardNews';
const News = (props) => {
  return (
    <CommonWrapper>
      <Layout className="layout">
        <StandardNews
          date={'11 October 2020'}
          title={'We are going live with our first game this month!'}
          description={
            'After few months of hard work Jumponce is going live this month on Android devices! Stay tuned and remember to follow us on Twitter to be up to date with our future news.'
          }
        />
      </Layout>
      <AnimationNews className="clickable" />
    </CommonWrapper>
  );
};

export default News;
