import styled from 'styled-components';

export const GameWrapper = styled.div`
  display: flex;
  width: 360px;
  margin-right: 45px;

  @media only screen and (max-width: 1199px) {
    width: 240px;
    margin-right: 16px;
  }

  @media only screen and (max-width: 767px) {
    width: 250px;
  }
`;
export const OuterWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
`;

export const GamePostWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 34px;
  grid-template-columns: 100px 220px;
  margin-top: 50px;
  width: 360px;

  @media only screen and (max-width: 1440px) {
    margin-top: 20px;
  }

  @media only screen and (max-width: 1199px) {
    grid-auto-flow: column;
    grid-template-columns: 66px auto;
    width: 245px;
    margin-top: 0;
    gap: 9px;
  }

  @media only screen and (max-width: 767px) {
    gap: 15px;
  }
`;
export const GameImage = styled.img`
  width: 100px;
  height: 100px;
  grid-gap: 18px;
  border-radius: 18px;
  background-color: #e5b50c;
  background-size: contain;

  @media only screen and (max-width: 1199px) {
    width: 66px;
    height: 66px;
  }
`;

export const GameContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  @media only screen and (max-width: 1199px) {
    align-items: center;
  }
`;

export const GameTitle = styled.h3`
  font-family: 'Segma';
  font-size: 24px;
  font-weight: bold;
  margin: 0;

  @media only screen and (max-width: 1199px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 20px;
  }
`;

export const GameDescription = styled.p`
  font-family: 'Segma';
  font-size: 16px;
  margin: 0;
  margin-top: 27px;

  @media only screen and (max-width: 1199px) {
    font-size: 16px;
    margin-top: 15px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

export const DownloadBox = styled.div`
  font-family: 'Segma';
  display: grid;
  grid-auto-flow: row;
  margin-top: 10px;
  font-size: 16px;

  @media only screen and (max-width: 1199px) {
    font-size: 14px;
    margin-top: 15px;
  }
`;

export const StoreButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-top: 10px;
  grid-template-columns: 100px 100px;
  gap: 14px;

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    grid-template-columns: 100px 69px;
    gap: 6.5px;
    margin-top: 5px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 5px;
  }
`;

export const StoreButtonWrapper = styled.a`
  cursor: none;
`;

export const StoreButton = styled.img`
  width: 100px;
  height: 30px;

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    width: 100px;
    height: 35px;
  }
`;

export const AnimationLetsPlay = styled.div`
  background-image: url('assets/images/animation_letsPlay.gif');
  width: 386px;
  height: 400px;
  background-size: cover;
  position: absolute;
  bottom: 180px;
  right: 16%;

  @media only screen and (max-width: 1300px) {
    width: 276px;
    height: 250px;
    bottom: 25%;
    right: 8%;
  }

  @media only screen and (max-width: 1199px) {
    width: 272px;
    height: 261px;
    right: 50%;
    transform: translateX(50%);
    bottom: 5%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const GamesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1000px;

  @media only screen and (max-width: 1199px) {
    width: 500px;
    row-gap: 53px;
    margin-top: 20px;
  }

  @media only screen and (max-width: 767px) {
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
  }
`;

export const More = styled.div`
  width: 100px;
  border: 2px solid #2a2a2a;
  border-radius: 7px;
  text-align: center;
  font-family: 'Segma';
  font-size: 16px;
  padding: 6px 0;
  margin-top: 27px;
  transition: background-color 0.1s ease-in;

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    width: 68px;
    font-size: 16px;
    margin-top: 15px;
    padding: 3px 0;
  }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    margin-top: 15px;
  }
`;
