import styled, { css } from 'styled-components';

const MODAL_BACKGROUND_Z_INDEX = 400;
const MODAL_Z_INDEX = 500;
const SET_INITIAL_POSITION = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export const MODAL_TRANSITION_TIME_MS = 330;
export const Container = styled.div.attrs({
  'data-cy': 'modal-container',
})`
  position: ${(p) => p.position};
  ${SET_INITIAL_POSITION};
  display: flex;
  opacity: 0;
  pointer-events: none;
  z-index: ${MODAL_Z_INDEX};
  transition: opacity ${MODAL_TRANSITION_TIME_MS}ms ease-in-out;
  ${(p) =>
    (p.status === 'entering' || p.status === 'entered') &&
    css`
      opacity: 1;
      pointer-events: all;
    `};

  @media only screen and (min-width: 2000px) {
    width: 60%;
  }
`;

export const Background = styled.div`
  position: fixed;
  ${SET_INITIAL_POSITION};
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  z-index: ${MODAL_BACKGROUND_Z_INDEX};
  transition: opacity ${MODAL_TRANSITION_TIME_MS}ms ease-in;
  ${(p) =>
    (p.status === 'entering' || p.status === 'entered') &&
    css`
      opacity: 1;
    `};
  @media only screen and (min-width: 2000px) {
    width: 60%;
  }
`;
